<template>
  <div class="w-100">
    <v-row justify="center">
      <v-col cols="12" xl="10" class="gestion-du-club__form">
        <FormAccueilEtContact
          :context="{ ID_Structure: this.structureID }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormAccueilEtContact from "@/components/Structure/AccueilEtContact/AccueilEtContact.vue";

export default {
  name: "AccueilEtContact",

  components: { FormAccueilEtContact },

  data: () => ({
    loading: false,
    structureID: null,
  }),

  async created() {
    this.setData();
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
  },
  methods: {
    setData() {
      this.structureID = parseInt(this.$route.params.id);
      if(!this.structureID) this.structureID = this.currentStructureId;
      console.log("this.structureID *********", this.structureID);
    },
  },
};
</script>
